import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { format } from 'date-fns';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import OrderListCard from '@/components/order/list/Card.vue';
import LeftFrame from '@/components/personal/LeftFrame.vue';
import { useStore } from 'vuex';
var tabsMapping = {
  UPCOMING: {
    label: '即將入住',
    include_status: ['UPCOMING']
  },
  CHECKED_IN: {
    label: '過去訂單',
    include_status: ['CHECKED_IN', 'CHECKED_OUT']
  },
  CANCELED: {
    label: '已取消',
    include_status: ['CANCELED']
  }
};
export default defineComponent({
  name: 'OrderList',
  components: {
    HeaderComponent: Header,
    FooterComponent: Footer,
    OrderListCard: OrderListCard,
    LeftFrame: LeftFrame
  },
  setup: function setup() {
    var store = useStore();
    var isMobile = inject('isMobile');
    var activeTab = ref('UPCOMING');
    var bookings = computed(function () {
      return store.state.user.bookings;
    });
    var groupedBookings = computed(function () {
      return bookings.value.reduce(function (acc, cur) {
        var date = format(new Date(cur.check_in), 'yyyy年MM月');
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, date, [].concat(_toConsumableArray(acc[date] || []), [cur])));
      }, []);
    });

    var changeTab = function changeTab(tab) {
      activeTab.value = tab;
    };

    watch(activeTab, function (v) {
      store.dispatch('user/getPersonalBookings', v);
    });
    onMounted(function () {
      store.dispatch('user/getPersonalBookings', activeTab.value);
    });
    return {
      isMobile: isMobile,
      tabsMapping: tabsMapping,
      activeTab: activeTab,
      changeTab: changeTab,
      bookings: bookings,
      groupedBookings: groupedBookings
    };
  }
});