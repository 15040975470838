import "core-js/modules/es.array.concat.js";
import { computed, defineComponent, inject } from 'vue';
import { format } from 'date-fns';
import { zhTW, enUS, ja } from 'date-fns/locale';
export default defineComponent({
  name: 'OrderListCard',
  props: ['booking'],
  setup: function setup(props) {
    var isMobile = inject('isMobile');
    var selectedLocale = inject('locale');
    var locale = selectedLocale === 'en' ? enUS : selectedLocale === 'jp' ? ja : zhTW;
    var startDate = computed(function () {
      return {
        date: isMobile ? format(new Date(props.booking.check_in), 'yyyy.MM.dd') : format(new Date(props.booking.check_in), 'MM/dd'),
        week: format(new Date(props.booking.check_in), 'eee', {
          locale: locale
        })
      };
    });
    var endDate = computed(function () {
      return {
        date: isMobile ? format(new Date(props.booking.check_out), 'yyyy.MM.dd') : format(new Date(props.booking.check_out), 'MM/dd'),
        week: format(new Date(props.booking.check_out), 'eee', {
          locale: locale
        })
      };
    });

    var checkDetail = function checkDetail() {
      window.open("/order?email=".concat(props.booking.email, "&number=").concat(props.booking.number), '_blank');
    };

    return {
      isMobile: isMobile,
      startDate: startDate,
      endDate: endDate,
      checkDetail: checkDetail
    };
  }
});